import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import CourseFooterNext from "../../../../../components/course-footer-next"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import FeedbackHeaderProgress from "../../../../../components/feedback-header-progress"
import Heading from "../../../../../components/heading"
import Text from "../../../../../components/text"
import Icon from "../../../../../components/icon"
import Inline from "../../../../../components/inline"
import Grid from "../../../../../components/grid"

const Page = () => {
  const { getAnswer, getTask, getStatsForTaskWithDict } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })
  const taskProps = {
    courseId: "haelfte-des-lebens",
    chapterId: "01-zwei-haelften",
    taskId: "lesen-und-einpraegen",
  }
  const task = getTask(taskProps)
  const answer = getAnswer(taskProps)
  const statistics = getStatsForTaskWithDict(taskProps)

  const RenderAnswer = ({ id }) => {
    const singleAnswer = answer?.[id]
    const solution = task.solution[id]

    const isValid = () => {
      if (typeof singleAnswer === "undefined" || singleAnswer === null) {
        return false
      }

      return solution === singleAnswer
    }

    return (
      <Inline alignY="center" space={1}>
        <Icon
          icon={isValid() ? "done" : "close"}
          color={isValid() ? "positive" : "negative"}
        />
        {singleAnswer || ""}
      </Inline>
    )
  }

  const RenderSolution = ({ id }) => {
    const solution = task.solution[id]

    return <Text>{solution}</Text>
  }

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          title="Alles klar soweit?"
          label="Weiter"
          to="/kurse/haelfte-des-lebens/01-zwei-haelften/spiel-mit-versgrenzen"
        />
      }
    >
      <Seo title="Lesen & Einprägen" />
      <Stack>
        <FeedbackHeaderProgress
          correct={statistics.correct}
          total={statistics.correct + statistics.incorrect + statistics.missed}
        />
        <Grid columns={[1, 2]}>
          <Stack space={6}>
            <Heading>Deine Antworten</Heading>
            <RenderAnswer id="q-01" />
            <RenderAnswer id="q-02" />
            <RenderAnswer id="q-03" />
            <RenderAnswer id="q-04" />
            <RenderAnswer id="q-05" />
            <RenderAnswer id="q-06" />
            <RenderAnswer id="q-07" />
            <RenderAnswer id="q-08" />
            <RenderAnswer id="q-09" />
          </Stack>
          <Stack space={6}>
            <Heading>Lösungen</Heading>
            <RenderSolution id="q-01" />
            <RenderSolution id="q-02" />
            <RenderSolution id="q-03" />
            <RenderSolution id="q-04" />
            <RenderSolution id="q-05" />
            <RenderSolution id="q-06" />
            <RenderSolution id="q-07" />
            <RenderSolution id="q-08" />
            <RenderSolution id="q-09" />
          </Stack>
        </Grid>
      </Stack>
    </LayoutCourse>
  )
}

export default Page
